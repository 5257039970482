import { formatEther } from '@ethersproject/units'
import { useTranslation } from '@pancakeswap/localization'
import { Button, Modal, Text, useModal, useToast } from '@pancakeswap/uikit'
import { ModalCheckPinCode } from 'components/ModalPin'
import { ToastDescriptionWithTx } from 'components/Toast'
import images from 'configs/images'
import { useCallWithMarketGasPrice } from 'hooks/useCallWithMarketGasPrice'
import useConfirmTransaction from 'hooks/useConfirmTransaction'
import { usePoolsV44Contract, usePoolsV4Contract } from 'hooks/useContract'
import { useMemo, useState } from 'react'
import CountUp from 'react-countup'
import { trendyColors } from 'style/trendyTheme'
import styled from 'styled-components'
import useSWR from 'swr'
import { getContract } from 'utils/contractHelpers'
import { useSigner } from 'wagmi'
import { abi } from './abi'
import { ClaimPoolModalProps } from './type'

// STYLE
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  justify-content: start;
  gap: 1em;
  .imagesvector {
    margin-left: 4px;
    padding: 3px;
    display: flex;
    width: 18px;
    height: 18px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--white-white-8, rgba(255, 255, 255, 0.08));
  }
`
const ClaimAmount = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledButton = styled(Button)`
  background: #8544f5;
`
const depositModal = {
  padding: '32px',
}
const depositInput = {
  borderRadius: '10px',
}
const ThreeDots = styled.p`
  @keyframes blinkdot {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  &.loading span {
    animation-name: blinkdot;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  &.loading span:nth-child(2) {
    animation-delay: 0.2s;
  }
  &.loading span:nth-child(3) {
    animation-delay: 0.4s;
  }
`
const Error = styled.span`
  margin: -0.5em 0 1em;
  color: ${trendyColors.ORANGE};
`

const useClaimContract = () => {
  const { data: signer } = useSigner()
  return useMemo(
    () =>
      getContract({
        abi: abi,
        address: '0xF68Dbc13fDcD445F8BD1E1820865A657bc8c2721',
        chainId: 137,
        signer,
      }),
    [signer],
  )
}

const ClaimPoolModal: React.FC<React.PropsWithChildren<ClaimPoolModalProps>> = ({
  pool,
  // isV2,
  account,
  onDismiss,
  onSuccess,
}) => {
  const { callWithMarketGasPrice } = useCallWithMarketGasPrice()
  const [confirmedTxHash, setConfirmedTxHash] = useState('')
  const { t } = useTranslation()
  const { toastSuccess, toastError } = useToast()
  const [isValidAmount, setIsValidAmount] = useState(true)
  const poolContract = usePoolsV4Contract()
  const poolContract44 = usePoolsV44Contract()
  const claimContract = useClaimContract()

  const ballance = useSWR(['/ballance', account], (_, account) => {
    return claimContract.getUserBalance().then((amount) => {
      return Number(formatEther(amount))
    })
  })

  const endTime = useSWR(['/endTime'], () => {
    return claimContract.endTime().then((endTime) => {
      return Number(endTime)
    })
  })

  const startTime = useSWR(['/startTimestamp'], () => {
    return claimContract.startTimestamp().then((startTimestamp) => {
      return Number(startTimestamp)
    })
  })

  const [openModal, onDismissModal] = useModal(
    <ModalCheckPinCode onDismiss={() => onDismissModal()} onCheck={() => onCheck()} />,
    false,
    false,
    'ModalCheckPinCode',
  )

  const onCheck = () => {
    handleConfirm()
  }

  const handleCheckPin = () => {
    const protectAccount = process.env.NEXT_PUBLIC_DEFAULT_PROTECT_ACCOUNT
    if (protectAccount.includes(account)) {
      openModal()
    } else {
      handleConfirm()
    }
  }

  const { isConfirming, handleConfirm } = useConfirmTransaction({
    onConfirm: () => {
      return claimContract.claimTokens()
    },
    onSuccess: async ({ receipt }) => {
      setConfirmedTxHash(receipt.transactionHash)
      toastSuccess(t('Claim reward successfully !'), <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
      onDismiss()
      onSuccess()
    },
  })

  return (
    <Modal
      style={depositModal}
      title={''}
      onDismiss={onDismiss}
      hideCloseButton={false}
      borderRadius={25}
      headerBackground="#24272A"
      background={'#24272A'}
    >
      <Wrapper>
        <ClaimAmount>
          <Text fontSize="24px">Claim</Text>
          <Text fontSize="18px">Current reward:</Text>
          <Text fontSize="16px" style={{ display: 'flex', color: '#8544F5' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CountUp
                separator=","
                start={0}
                preserveValue
                delay={0}
                end={ballance.data}
                decimals={!ballance.data ? 0 : 4}
                duration={0.5}
              />
              &ensp;
              <img className="imagesvector" src={images.vector} alt="pool name" width={18} />
            </div>
          </Text>
        </ClaimAmount>
        <StyledButton
          variant={'danger'}
          width="180px"
          disabled={
            isConfirming ||
            !ballance.data ||
            endTime.data < new Date().getTime() / 1000 ||
            startTime.data > new Date().getTime() / 1000
          }
          onClick={handleConfirm}
        >
          {isConfirming ? (
            <ThreeDots className="loading">
              Claiming<span>.</span>
              <span>.</span>
              <span>.</span>
            </ThreeDots>
          ) : startTime.data > new Date().getTime() / 1000 ? (
            'Upcoming'
          ) : endTime.data < new Date().getTime() / 1000 ? (
            'Ended'
          ) : (
            'Claim'
          )}
        </StyledButton>
      </Wrapper>
    </Modal>
  )
}

export default ClaimPoolModal
